<template>
  <span>
    <div class="the-navbar__user-meta flex items-center" v-if="isLogin">
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon
          icon="BookOpenIcon"
          class="cursor-pointer mt-1 sm:mr-6 mr-2"
          title="คู่มือการใช้งาน K-VISIT Cloud Management"
        />

        <vs-dropdown-menu
          class="notification-dropdown dropdown-custom vx-navbar-dropdown"
          style="width: 280px;"
        >
          <div class="notification-top text-center p-5 bg-primary text-white">
            <h3 class="text-white">คู่มือ</h3>
          </div>
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0 mb-10"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items">
              <li
                v-for="ntf in listMannulManagement"
                :key="ntf.index"
                class="flex justify-between px-4 py-4 notification cursor-pointer"
              >
                <a :href="ntf.path" target="_blank">
                  <div class="flex items-start">
                    <feather-icon
                      :icon="ntf.icon"
                      :svgClasses="[
                        `text-${ntf.category}`,
                        'stroke-current mr-1 h-6 w-6'
                      ]"
                    ></feather-icon>
                    <div class="mx-2">
                      <span class="font-medium block notification-title">{{
                        ntf.title
                      }}</span>
                      <!-- <small>{{ ntf.msg }}</small> -->
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </component>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div class="the-navbar__user-meta flex items-center" v-else>
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon
          icon="BookOpenIcon"
          class="cursor-pointer mt-1 sm:mr-6 mr-2"
          title="คู่มือการใช้งาน K-VISIT Cloud"
        />

        <vs-dropdown-menu
          class="notification-dropdown dropdown-custom vx-navbar-dropdown"
          style="width: 280px;"
        >
          <div class="notification-top text-center p-5 bg-primary text-white">
            <h3 class="text-white">คู่มือ</h3>
          </div>
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0 mb-10"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items">
              <li
                v-for="ntf in listMannul"
                :key="ntf.index"
                class="flex justify-between px-4 py-4 notification cursor-pointer"
              >
                <a :href="ntf.path" target="_blank">
                  <div class="flex items-start">
                    <feather-icon
                      :icon="ntf.icon"
                      :svgClasses="[
                        `text-${ntf.category}`,
                        'stroke-current mr-1 h-6 w-6'
                      ]"
                    ></feather-icon>
                    <div class="mx-2">
                      <span class="font-medium block notification-title">{{
                        ntf.title
                      }}</span>
                      <!-- <small>{{ ntf.msg }}</small> -->
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </component>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management ข่าวสาร"
      :active.sync="popupActiveNews"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="เพิ่มข้อมูลข่าวสาร">
          <span>1. เมื่อล็อกอินเข้ามาแล้วระบบจะพามาหน้าจัดการข่าวสาร</span><br>
          <span
            >1.1
            ให้ทางเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องเพิ่มข้อมูลข่าวสารปุ่มสีม่วงเพิ่มข่าวสารด้านขวาด้านบน
            เมื่อกดเข้าไปแล้วระบบจะให้กรอกหัวข้อข่าวและรายละเอียดที่ต้องการจะประการเสร็จแล้วให้กดบันทึก</span
          >
          <img :src="getURL('mannul_news_add-1.png')" style="width: 100%;">
          <img :src="getURL('mannul_news_add-2.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="แก้ไข-ลบ ข้อมูลข่าวสาร">
          <span
            >2.
            เมื่อเพิ่มข้อมูลข่าวสารเสร็จแล้วระบบจะพากลับมาโชว์หน้าข้อมูลข่าวสาร
            ทางเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องหากต้องการแก้ไข้ข้อมูลหรือลบข้อมูลข่าวสาร
            ให้กดไปที่รูปดินสอเพื่อแก้ไข้ข้อมูล
            หากต้องการลบให้กดไปที่รูปถังขยะ.</span
          >
          <img :src="getURL('mannul_news_editdelete.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management จัดการลูกบ้าน"
      :active.sync="popupActiveHome"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>1.การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="เพิ่มข้อมูลลูกบ้าน">
          <span>2. เมื่อล็อกอินเข้าเว็บแล้วระบบจะพาไปหน้าเมนูหลักจัดการลูกบ้าน</span><br>
          <span
            >2.1 ให้ทางเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องกดเพิ่มข้อมูลลูกบ้าน เมื่อกดเพิ่มลูกบ้านระบบจะให้กรอก
            ข้อมูลลูกบ้าน ให้กรอกข้อมูลลูกบ้านให้ครบถ้วนเสร็จแล้วให้กดปุ่มบันทึกข้อมมูล </span>
            <img :src="getURL('mannul_home_add.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="สร้าง QR">
          <span>3. เมื่อทำการกดเพิ่มลูกบ้านบันทึกข้อมูลลูกบ้านเสร็จเรียบร้อยแล้ว ระบบจะพากลับมาหน้า
            จัดการลูกบ้าน ให้ทางเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องกดสร้าง QR เพื่อนำไปแจกให้ลูกบ้าน</span>
          <img :src="getURL('mannul_home_creadeQR.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="แก้ไข-ลบ">
          <span>3.1 ทางเจ้าหน้าที่หรือผู้เกี่ยวข้อง สามารถแก้ไข้ข้อมูลหรือลบข้อมูลได้ แก้ไข้ข้อมูลให้เลือกไปที่
            รูปดินสอหากต้องการลบข้อมูลให้เลือกไปที่รูปถังขยะ</span>
          <img :src="getURL('mannul_home_editdelete.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="เปิดปิดบ้านลูกบ้าน">
          <span>4. หากเจ้าหน้าที่ต้องการที่จะเปิดปิดระบบการเปิดปิดบ้านของลูกบ้าน ให้เลือกไปที่แก้ไขเมื่อคลิก
          เข้าไปแล้ว ระบบจะแสดงหน้าข้อมูลลูกบ้าน ให้กดไปที่ช่องสี่เหลี่ยมมุมซ้ายด้านล่าง เสร็จแล้วให้กดบันทึกข้อมูล
          </span>
          <img :src="getURL('mannul_home_edit-openclose.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management ข้อมูลรถเข้า-ออก"
      :active.sync="popupActiveTransactions"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>1.การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="รายละเอียด">
          <span>2. หากเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องต้องการจะตรวจสอบข้อมูลรถเข้า-ออก ให้กดล็อกอิน
            เข้ามาที่เว็บระบบจะแสดงหน้าข้อมูลรถเข้า-ออก</span><br>
          <span
            >2.1 เจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้อง สามารถกดเลือกดูข้อมูลรถเข้า-ออก ระบบจะแสดงผลข้อมูล
            รถเข้าออก หากต้องการทราบข้อมูลรถเข้า-ออก ในแต่ละคันให้กดเลือกรูปไอคอนตาระบบจะ
            แสดงรายละเอียดข้อมูลรถเข้า-ออกทั้งหมดคันที่เจ้าหน้าที่ได้เลือก</span>
            <img :src="getURL('mannul_transactions_dateil.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="ค้นหาข้อมูล">
          <span>3. เจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องสามารถกดค้นหาข้อมูลรถเข้า-ออกได้ โดยกดเลือกค้นหา
            ระบบจะให้เลือกการกดค้นหา โดยมีให้เลือกวันที่รถเข้า เลขที่ติดต่อ และทะเบียนรถ</span><br>
          <span>3.1 เมื่อกรอกข้อมูลลงระบบเสร็จแล้ว ระบบจะแสดงผลข้อมูลที่ได้เลือกค้นหา</span>
          <img :src="getURL('mannul_transactions_search.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="Export Excel">
          <span>4. เจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้อง สามารถกดเลือก Export เพื่อเลือกตั้งค่าเริ่มต้นวันที่รถเข้าและวันที่สิ้นสุดเวลาเข้า</span><br>
          <span>4.1 เมื่อกดเลือก Export ระบบจะให้เลือกวันที่เริ่มต้นเวลเข้า และวันที่สิ้นสุดเวลาเข้า เมื่อท าการ
            กรอกข้อมูลลงระบบเสร็จแล้ว ให้กด Export Excel</span>
            <img :src="getURL('mannul_transactions_export-excel.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management ข้อมูลแจ้งปิดบ้าน"
      :active.sync="popupActiveCloseHome"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>1.การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="ข้อมูลปิดเปิดบ้าน">
          <span>2. หากเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องต้องการจะตรวจสอบข้อมูลลูกบ้าน ว่ามีลูกบ้านหลังไหน
            บ้างที่มีการแจ้งปิดบ้าน ให้กดล็อกอินเข้ามาที่เว็บ ระบบจะแสดงหน้าข้อมูลแจ้งปิดบ้าน
            เจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องสามารถกดลบข้อมูลได่</span>
            <img :src="getURL('mannul_close_home.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management ข้อมูลแขกวีไอพี"
      :active.sync="popupActiveVip"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>1.การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="หน้าข้อมูลแขกวีไอ">
          <span>2. หากเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องต้องการจะตรวจสอบข้อมูลลูกบ้าน ว่ามีลูกบ้านหลังไหน
            บ้างที่มีการแจ้งข้อมูลแขกวีไอพี ให้กดล็อกอินเข้ามาที่เว็บระบบจะแสดงหน้าข้อมูลแขกวีไอพี
            เจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องสามารถกดลบข้อมูลได้ ลบข้อมูลให้กดเลือกรูปถังขยะสีม่วง</span>
            <img :src="getURL('mannul_vip.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="คู่มือการใช้ K-VISIT Cloud Management ข้อมูลเรียกใช้บริการ"
      :active.sync="popupActiveRequestHelp"
    >
      <vs-tabs position="left" color="primary">
        <vs-tab label="การเข้าใช้ระบบ">
          <span>1.การเข้าใช้ระบบ หน้า login ให้ใส่ Email และรหัสผ่าน</span>
          <img :src="getURL('login.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="หน้าข้อมูลลูกบ้านเรียกใช้บริการ">
          <span>2. หากเจ้าหน้าที่หรือผู้มีส่วนเกี่ยวข้องต้องการจะตรวจสอบข้อมูลลูกบ้าน ว่ามีลูกบ้านหลังไหนบ้างที่
            มีการแจ้งข้อมูลเรียกใช้บริการให้กดล็อกอินเข้ามาที่เว็บระบบจะแสดงหน้าข้อมูลเรียกใช้บริการ</span>
            <img :src="getURL('mannul_request_help.png')" style="width: 100%;">
        </vs-tab>
        <vs-tab label="สถานะการใช้บริการ">
          <span>2.1 หากข้อมูลที่ลูกบ้านแจ้งเรียกใช้บริการ รปภ ได้เปิดรับข้อมูล ระบบจะแจ้งดำเนินการแล้ว
            หาก รปภ ยังไม่ได้เปิดรับข้อมูลระบบจะแจ้งรอดำเนินการ เจ้าหน้าที่หรือผู้เกี่ยวข้องสามารถกดลบ
            ข้อมูลการเรียกใช้บริการได้</span>
            <img :src="getURL('mannul_request_help-status.png')" style="width: 100%;">
        </vs-tab>
      </vs-tabs>
    </vs-popup>
  </span>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      listMannulManagement: [
        {
          index: 0,
          title: "คู่มือการใช้ จัดการข่าวสาร",
          msg: "Are your going to meet me tonight?",
          icon: "BookmarkIcon",
          category: "primary",
          typePopup: "news",
          path: "/file/1.pdf"
        },
        {
          index: 1,
          title: "คู่มือการใช้ จัดการลูกบ้าน",
          msg: "You got new order of goods.",
          icon: "BookmarkIcon",
          category: "success",
          typePopup: "home",
          path: "/file/2.pdf"
        },
        {
          index: 2,
          title: "คู่มือการใช้ ข้อมูลรถเข้า-ออก",
          msg: "Server have 99% CPU usage.",
          icon: "BookmarkIcon",
          category: "danger",
          typePopup: "transactions",
          path: "/file/3.pdf"
        },
        {
          index: 3,
          title: "คู่มือการใช้ ข้อมูลแจ้งปิดบ้าน",
          msg: "Cake sesame snaps cupcake",
          icon: "BookmarkIcon",
          category: "primary",
          typePopup: "close_home",
          path: "/file/4.pdf"
        },
        {
          index: 4,
          title: "คู่มือการใช้ ข้อมูลแขกวีไอพี",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "BookmarkIcon",
          category: "warning",
          typePopup: "vip",
          path: "/file/5.pdf"
        },
        {
          index: 5,
          title: "คู่มือการใช้ ข้อมูลเรียกใช้บริการ",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "BookmarkIcon",
          category: "success",
          typePopup: "request_help",
          path: "/file/6.pdf"
        },
          {
          index: 6,
          title: "คู่มือการใช้ ข้อมูลการเดินตรวจ",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "BookmarkIcon",
          category: "primary",
          typePopup: "request_help",
          path: "/file/7.pdf"
        }
      ],
      listMannul: [
        {
          index: 0,
          title: "คู่มือการใช้ ระบบข่าวสาร",
          msg: "Are your going to meet me tonight?",
          icon: "BookmarkIcon",
          category: "primary",
          typePopup: "news",
          path: "/file/u1.pdf"
        },
        {
          index: 1,
          title: "คู่มือการใช้ ระบบเปิดปิดบ้าน",
          msg: "You got new order of goods.",
          icon: "BookmarkIcon",
          category: "success",
          typePopup: "home",
          path: "/file/u2.pdf"
        },
        {
          index: 2,
          title: "คู่มือการใช้ ระบบตราประทับ",
          msg: "Server have 99% CPU usage.",
          icon: "BookmarkIcon",
          category: "danger",
          typePopup: "transactions",
          path: "/file/u3.pdf"
        },
        {
          index: 3,
          title: "คู่มือการใช้ ระบบ VIP",
          msg: "Cake sesame snaps cupcake",
          icon: "BookmarkIcon",
          category: "primary",
          typePopup: "close_home",
          path: "/file/u4.pdf"
        },
        {
          index: 4,
          title: "คู่มือการใช้ ระบบเรียกใช้บริการ",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "BookmarkIcon",
          category: "warning",
          typePopup: "vip",
          path: "/file/u5.pdf"
        }
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      isLogin: false,
      userInfo: null,
      roleInfo: null,
      popupActive: false,
      popupActiveNews: false,
      popupActiveHome: false,
      popupActiveTransactions: false,
      popupActiveCloseHome: false,
      popupActiveVip: false,
      popupActiveRequestHelp: false,
    };
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    getUserInfo() {
      return this.userInfo != null;
    }
  },
  methods: {
    elapsedTime(startTime) {
      const x = new Date(startTime);
      const now = new Date();
      let timeDiff = now - x;
      timeDiff /= 1000;

      const seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      const minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      const hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      const days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      const years = timeDiff;

      if (years > 0) {
        return `${years + (years > 1 ? " Years " : " Year ")}ago`;
      } else if (days > 0) {
        return `${days + (days > 1 ? " Days " : " Day ")}ago`;
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? " Hrs " : " Hour ")}ago`;
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? " Mins " : " Min ")}ago`;
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      const date = new Date();

      if (hr) date.setHours(date.getHours() - hr);
      if (min) date.setMinutes(date.getMinutes() - min);
      if (sec) date.setSeconds(date.getSeconds() - sec);

      return date;
    },
    popupMannal(type) {
      if (type === "news") {
        this.popupActiveNews = true;
      } else if (type === "home") {
        this.popupActiveHome = true;
      } else if (type === "transactions") {
        this.popupActiveTransactions = true;
      } else if (type === "close_home") {
        this.popupActiveCloseHome = true;
      } else if (type === "vip") {
        this.popupActiveVip = true;
      } else if (type === "request_help") {
        this.popupActiveRequestHelp = true;
      }
    },
    getURL(assetName) {
      return  require('@/assets/images/mannul/'+assetName);
    }
  },
  created() {
    this.userInfo = $cookies.get("userInfo");

    if (this.userInfo !== null) {
      this.isLogin = true;
    }

    console.log("ProfileDropdown **this.userInfo", this.userInfo);
    //  if( this.userInfo == null || this.userInfo.user == null) {
    //     this.$router.push("pages/login");
    //  }
    this.roleInfo = $cookies.get("roleInfo");
    console.log("ProfileDropdown **this.roleInfo", this.roleInfo);
    // http://localhost:8080/pages/login
  }
};
</script>
