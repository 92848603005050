<template>
<span>
  <!-- <span>isLogin={{isLogin}}</span> -->

  <div class="the-navbar__user-meta flex items-center" v-if="isLogin">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userInfo.user.email }}</p>
      <small>{{roleInfo != null ? roleInfo.customer_name : ""}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">


      <div class="con-img ml-3" style="background-color:red;" v-if="is_god == '1'">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="'https://cdn0.iconfinder.com/data/icons/set-ui-app-android/32/8-512.png'" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>
        <div class="con-img ml-3" v-if="is_god == '0'">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="'https://cdn0.iconfinder.com/data/icons/set-ui-app-android/32/8-512.png'" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li>

          <vs-divider class="m-1" /> -->
            <li @click="createQR('old:'+roleInfo != null ? roleInfo.customer_name : '')" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <span class="ml-2">QR Code</span>
          </li>

          <vs-divider class="m-1" />
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
  </span>
</template>

<script>
import Vue from "vue";
import { firebaseApp } from "../../../../store/firebaseDb";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);


export default {
  data() {
    return {
      isLogin: false,
      userInfo:null,
      is_god: "0",
      roleInfo:null,
    };
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    getUserInfo(){
      return this.userInfo != null;
    }

  },
  methods: {
    createQR(_uid) {
      console.log("createQR uid="+_uid);
      // alert("_uid1="+_uid);
      // return;
      
      _uid = window.btoa(encodeURIComponent("kvz:k-visit-old:"+_uid));
      _uid = window.btoa(_uid);
      // const uidEncode = encodeURIComponent(_uid);

      const url = "https://api.qrserver.com/v1/create-qr-code/?color=0000FF&size=3000x300&data=" +
      _uid;
      window.open(url, "_blank", "");

    },
    logout () {
      localStorage.removeItem('userInfo')
      // This is just for demo Purpose. If user clicks on logout -> redirect
        firebaseApp
       .auth()
       .signOut()
       .then(() => {
            Vue.$cookies.remove("customer");
            Vue.$cookies.remove("home");
            Vue.$cookies.remove("roleInfo");
            Vue.$cookies.remove("userInfo");
            // this.$router.push('/pages/login').catch(() => {})
            this.$router.push("/").catch(error => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
       });
    }
  },
  beforeCreate(){

  },
  created() {
     this.userInfo = $cookies.get("userInfo");
     this.is_god = $cookies.get("is_god");

    if (this.userInfo !== null){
      this.isLogin = true;
    }
    console.log("ProfileDropdown **this.userInfo",this.userInfo)
    //  if( this.userInfo == null || this.userInfo.user == null) {
    //     this.$router.push("pages/login");
    //  }
    this.roleInfo = $cookies.get("roleInfo");
    console.log("ProfileDropdown **this.roleInfo",this.roleInfo)
    // http://localhost:8080/pages/login

  },
}
</script>
