<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper">

      <vs-navbar
        v-if="isHomeUser == false"
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <vs-spacer />

        <!-- <search-bar class="mr-4" /> -->

        <!-- <notification-drop-down /> -->

        <mannul-drop-down />

        <profile-drop-down />
      </vs-navbar>

      <vs-navbar
        class="k-top-menu-v vx-navbar navbar-custom navbar-skelton"
        v-if="isHomeUser == true"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <span class="k-top-menu-v justify-center">
          <feather-icon icon="MenuIcon"  v-on:click="menuActive = !menuActive" />
        </span>
        <vs-spacer />
        <mannul-drop-down />
      </vs-navbar>
      <div v-if="menuActive" class="k-top-menu-v justify-cent">
          <vs-navbar-item index="0">
            <router-link
              tag="div"
              to="/"
              class="vx-logo"
              style="margin-bottom: 20px"
            >
              <span class="vx-logo-text text-primary">K-VISIT</span>
            </router-link>
          </vs-navbar-item>
          <vs-navbar-item
            index="1"
            v-if="user_cloud_config != null"
          >
            <a href="/home-user-news">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-news-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>ข่าวสาร</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="2"
            v-if="user_cloud_config != null"
          >
            <a href="/close-house">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-open-close-home-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>เปิดปิดบ้าน</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="3"
            v-if="
              user_cloud_config != null
            "
          >
            <a href="/stamp">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-stamp-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>ประทับตรา</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="4"
            v-if="user_cloud_config != null"
          >
            <a href="/home-user-vip">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-fix-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>แขกวีไอพี</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="5"
            v-if="user_cloud_config != null"
          >
            <a href="/house-request-help">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-chat-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>เรียกใช้บริการ</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
      </div>

      <!-- <vs-navbar
        v-if="isHomeUser == true"
        class="k-top-menu-v justify-center"
        vs-align="center"
        style="text-align: center"
        :color="navbarColorLocal"
      >
      <div class="">
          <vs-navbar-item index="0">
            <router-link
              tag="div"
              to="/"
              class="vx-logo"
              style="margin-bottom: 20px"
            >
              <span class="vx-logo-text text-primary">K-VISIT</span>
            </router-link>
          </vs-navbar-item>
          <vs-navbar-item
            index="1"
            v-if="user_cloud_config != null"
          >
            <a href="/home-user-news">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-news-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>ข่าวสาร</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="2"
            v-if="user_cloud_config != null"
          >
            <a href="/close-house">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-open-close-home-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>เปิดปิดบ้าน</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="3"
            v-if="
              user_cloud_config != null
            "
          >
            <a href="/stamp">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-stamp-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>ประทับตรา</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="4"
            v-if="user_cloud_config != null"
          >
            <a href="/home-user-vip">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-fix-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>แขกวีไอพี</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
          <vs-navbar-item
            index="5"
            v-if="user_cloud_config != null"
          >
            <a href="/house-request-help">
              <div class="k-icon-box">
                <div slot="media" class="k-icon">
                  <img src="@/assets/images/k/k-chat-icon.png" />
                </div>
                <div class="k-label">
                  <span><h5>เรียกใช้บริการ</h5></span>
                </div>
              </div>
            </a>
          </vs-navbar-item>
        </div>
      </vs-navbar> -->
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import Bookmarks from "./components/Bookmarks.vue";
import SearchBar from "./components/SearchBar.vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import MannulDropDown from "./components/MannulDropDown.vue";
import { db, firebaseApp } from "../../../store/firebaseDb";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      activeClass: "selected-menu",
      hNumber: "",
      user_cloud_config: {},
      menuActive: false
    };
  },
  beforeCreate() {
    console.log("[[beforeCreate Ver]]");

   /* this.isHomeUser = false;
    this.hNumber = $cookies.get("home");

    if( this.hNumber != null && this.hNumber+"".length > 2){
      this.isHomeUser = true;
    } else {
      this.$router.push("/pages/login").catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
    }*/


    /*firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
        var home = $cookies.get("home");
        if (home == null) {
          this.$router.push("/pages/login").catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      }
    });*/
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
    MannulDropDown,
  },
  computed: {
    isHomeUser(){
      this.hNumber = $cookies.get("home");
      var _isHomeUser = false
      var _length = (this.hNumber+"").length

      if( this.hNumber != null && _length >= 1){
        _isHomeUser = true;
      }

      console.log("_isHomeUser="+_isHomeUser);

      return _isHomeUser

    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor !== "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor !== "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === "default") return "navbar-default";
      else if (this.verticalNavMenuWidth === "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
  },
  created() {
    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.startInterval();
  },
  methods: {
    startInterval() {
      setInterval(() => {
        this.user_cloud_config = $cookies.get("user_cloud_config");
      }, 2000);
    },

    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
  },
};
</script>

<style>
.k-top-menu-v {
      overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
  padding: 10px 0 10px 0;
  background-color: white !important;
  text-align: center;
}

.k-top-menu-v .k-icon-box {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 10px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.k-top-menu-v .k-label {
  text-align: center;
}

.k-top-menu-v img {
  width: 50px;
}

.k-top-menu-v .con-vs-card {
  margin: 0px;
}

.selected-menu-v h5 {
  color: #7367ef;
  border-bottom: 4px solid;
}


.navbar-custom2 .vs-navbar--btn-responsive {
  /* display: none !important; */
}
.navbar-custom2 .vs-spacer {
	 /* display: block !important; */
}
.navbar-custom2 .vs-con-items {
	/* display: flex !important; */
	width: 100%;
}

</style>

